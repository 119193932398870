import React from "react";
import styles from "./page-news.module.css";

import Section from "./section";
import PostList from "./post-list";

const PageNews = ({ posts }) => {
  return (
    <>
      <Section bg="lightGeo">
        <div className={styles.inner}>
          <h1 className={styles.title}>What are the haps?</h1>
          <p className={styles.para}>{/* insert copy here */}</p>
        </div>
        <PostList posts={posts} />
      </Section>
    </>
  );
};

export default PageNews;
