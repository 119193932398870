import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import clientConfig from "../../client-config";
import { getFixedGatsbyImage } from "gatsby-source-sanity";
import { getBlogUrl } from "../lib/helpers";
import styles from "./post-list.module.css";
import PortableText from "./portableText";
import { format, distanceInWords, differenceInDays } from "date-fns";

const PostList = ({ posts }) => {
  return (
    <div>
      {posts.map((post) => (
        <Link
          key={post.slug.current}
          className={styles.post}
          to={getBlogUrl(post.publishedAt, post.slug)}
        >
          <div className={styles.imageWrapper}>
            <img
              src={`https://cdn.sanity.io/${post.mainImage.asset.path}`}
              className={styles.image}
              alt={post.title}
            />
          </div>
          <div className={styles.content}>
            <h3 className={styles.title}>{post.title}</h3>
            <div className={styles.excerpt}>
              <PortableText blocks={post._rawExcerpt} />
            </div>
            <div className={styles.date}>
              {differenceInDays(new Date(post.publishedAt), new Date()) > 3
                ? distanceInWords(new Date(post.publishedAt), new Date())
                : format(new Date(post.publishedAt), "MMMM Do, YYYY")}
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default PostList;
