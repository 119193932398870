import React from "react";
// import { Link } from 'gatsby'
// import {cn} from '../lib/helpers'
import Button from "./button";
import Banner from "./banner";

import styles from "./banner-newsletter.module.css";
import bannerStyles from "./banner.module.css";

const BannerWorkWithUs = () => {
  const handleClick = () => {
    window.location.href = "mailto:mail@example.org";
  };

  return (
    <Banner bg="lightGeo">
      <div className={styles.center}>
        <h2 className={bannerStyles.title}>Keep in touch</h2>
        <p className={bannerStyles.para}>
          Be sure you’re getting the latest news and some unrelated random things we like. Not your
          average newsletter.
        </p>
        <div id="mc_embed_signup">
          <form
            action="https://squirestudio.us17.list-manage.com/subscribe/post?u=a905a437b505c5547486e9ed5&amp;id=e9551ae2a5"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            target="_blank"
            noValidate
          >
            <div className={styles.form} id="mc_embed_signup_scroll">
              <input
                className={styles.input}
                type="email"
                name="EMAIL"
                id="mce-EMAIL"
                placeholder="Your email address..."
              />
              <span style={{ position: "absolute", left: "-5000px" }} aria-hidden="true">
                <input
                  type="text"
                  tabIndex="-1"
                  placeholder="Your email address..."
                  name="b_66a0338f342ceae82ebff5386_67fb0b9b47"
                />
              </span>
              <input
                className={styles.submit}
                type="submit"
                value="Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
              />
            </div>
          </form>
        </div>
      </div>
    </Banner>
  );
};

export default BannerWorkWithUs;
